import React, { useState, useEffect, useRef, memo, lazy, Suspense,useCallback } from "react";
import styled from "styled-components";
import axios from "axios";
//components
import HeaderLayout from "../styles/HeaderLayout";



import  NavBar from './NavBar/NavBar';
import ButtonsVolant from'./NavBar/ButtonsVolant';
import BandeauInfoHP from'./BandeauInfo/BandeauInfo';

const StickyHeader = styled(HeaderLayout)`
  @media (min-width: 1200px) {

    opacity: ${(props) => (props.isVisible ? "1" : "0")};
    transition:
      opacity 0.3s ease-in-out,
      transform 0.5s ease-in-out;
      position: ${(props) => (props.isSticky ? "sticky" : "fixed")};
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    transform: translateY(${(props) => (props.isVisible ? "0%" : "-100%")})
      scale(${(props) => (props.isVisible ? "1" : "0.9")});
  }
`;
const CLE_API = process.env.GATSBY_CLE_API;
const Header = () => {
  const [showBandeauInfo, setShowBandeauInfo] = useState(false);
  const classNameB = showBandeauInfo ? "" : "hiddenBandeau";
  const [dataBannieres, setDataBannieres] = useState(null);
  const [contientForm, setContientForm] = useState(false);
  const [showBtnDoc, setShowBtnDoc] = useState(false);
  const [showBtnMenu, setShowBtnMenu] = useState(true);
  const [buttonsVolantsScrollValue, setButtonsVolantsScrollValue] =
    useState(400);
  const [showBtn, setShowBtn] = useState(true);
  const [scrollValue, setScrollValue] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
const [IDContenu, setIDContenu] = useState(0);

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visibleBanners, setVisibleBanners] = useState([]);
  const [isSticky, setIsSticky] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const lastScrollTop = useRef(); // Initialize to 0
  const navbarHeight = 80;

  const timeoutsRef = useRef([]);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1200);
    setShowBtnDoc(contientForm);
    setShowBtnMenu(isMobile);
  }, [contientForm, isMobile]);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`/ysw/bannieres/?{"Act":"Bannieres", "Slug":"${window.location.pathname}","CleCtrl":"${CLE_API}"}`);
        console.log(res, "response");
        setDataBannieres(res.data.Bannieres);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    })();
  }, []);

  useEffect(() => {
if (!dataBannieres) return;
    timeoutsRef.current.forEach(clearTimeout);
    timeoutsRef.current = [];
    const largestDelay = Math.max(...dataBannieres?.map(banner => banner.DelaiAffichage));
    let cumulativeDelay = 0; // Initialize cumulative delay
    const newVisibleBanners = dataBannieres.map(() => false);
  
    dataBannieres.forEach((banner, index) => {
      cumulativeDelay = banner.DelaiAffichage; // Accumulate delay
      const timeoutId = setTimeout(() => {
        newVisibleBanners[index] = true;
        setVisibleBanners([...newVisibleBanners]);
      }, cumulativeDelay * 1000); // Use cumulative delay for sequential display
  
      timeoutsRef.current.push(timeoutId);

      const showBandeauInfoTimeoutId = setTimeout(() => {
        setShowBandeauInfo(true);
      }, largestDelay * 1000);
    
      timeoutsRef.current.push(showBandeauInfoTimeoutId);
    
    });
  console.log(`cumulativeDelay: ${cumulativeDelay}`);
    // Set bandeau info to be shown after the cumulative delay of all banners
 
    return () => {
      timeoutsRef.current.forEach(clearTimeout);
    };
  }, [dataBannieres]);
  function handleCloseSlide(i) {
    const newDataBannieres = dataBannieres.filter((item, index) => index !== i);
    setDataBannieres(newDataBannieres);
  }
// useEffect(() => {
//   console.log(`showBandeauInfo changed to: ${showBandeauInfo}`);
// }, [showBandeauInfo]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      console.log('Scroll Top:', scrollTop, 'Last Scroll Top:', lastScrollTop.current);
      if (scrollTop > lastScrollTop.current && scrollTop > navbarHeight) {
        console.log('Hiding header');
        setIsVisible(false);
    
      } else {
        console.log('Showing header');
        setIsVisible(true);
        if (scrollTop === 0) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }
      lastScrollTop.current = scrollTop <= navbarHeight ? 0 : scrollTop;
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isVisible]);
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const res = await axios.get(`/ysw/boutonflotants/?{"Act":"BoutonFlotants", "Slug":"${window.location.pathname}","CleCtrl":"${CLE_API}"}`);
  //       console.log(res, "response");
  //       setIDContenu(res.data.IDContenu);
  //       setContientForm(res.data.contientForm1);
  //       setShowBtnDoc(res.data.contientForm1);
  //       setShowBtnMenu(isMobile);
  //       const scrollValue =
  //         res?.data?.scrollValue
  //           ? isMobile
  //             ? 750
  //             : 950
  //           : 400;
  //       setButtonsVolantsScrollValue(scrollValue);
  //     } catch (error) {
  //       console.error("Failed to fetch data:", error);
  //     }
  //   })();
  // }, []);

  return (
    <div>
      <StickyHeader isVisible={isVisible} isSticky={isSticky}>
        {  showBandeauInfo && (
        
          <BandeauInfoHP
            dataBannieres={dataBannieres}
            visibleBanners={visibleBanners}
            handleCloseSlide={handleCloseSlide}
          />
         
        )}
        <NavBar
          classNameB={classNameB}
          isVisible={isVisible}
          showBtnDoc={showBtnDoc}
        />
      </StickyHeader>
  
      <ButtonsVolant
        idContenu={IDContenu}
        showBtn={showBtn}
        showBtnDoc={showBtnDoc}
        showBtnMenu={showBtnMenu}
        scrollValue={scrollValue}
      />

    </div>
  );
};
export default Header;
