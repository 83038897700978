import React, { useState, useRef, useEffect } from "react";
import BoutonJaune from "../DesignSystem/BoutonJaune";
import { SelectFilters, TextareaStyled, Chars } from "../DesignSystem/Commons/Elements";
import { Legal } from "../DesignSystem/Commons/Containers";
import { FormConnect, InputStyled } from "../DesignSystem/Commons/Forms";
import BoutonJauneInverseHP from "../DesignSystem/BoutonJauneInverse";
import { ButFermer } from "../DesignSystem/PopUp";
import { IoCloseCircleOutline } from "react-icons/io5";
import { RowStyledForm, ButsForm } from "../DesignSystem/Commons/Containers";
import PopErrorHP from "../Popups/PopError";
import axios from "axios";
import { useLocation } from "@reach/router"
import { message } from "antd";
import SpinnerInline2HP from "../Spinners/SpinnerInline2";

function FormDemandeDeContactHP({ onClick, className, placeholder, idContenus, typeProd }) {
    const getTodayDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();

        return `${yyyy}-${mm}-${dd}`;
    };
    const location = useLocation();
    const user = null;
    const maxChars = 2000; 
    const formRef = useRef();
    const [open, setOpen] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [txtError, setTxtError] = useState("");
    const [form, setForm] = useState({
        Origine: "",
        URL: location.pathname,
        Nom: "",
        Prenom: "",
        Mail: "",
        Tel: "",
        Question: "",
        Civ: "",
        Adresse: "",
        CP: "",
        Ville: "",
        Pays: "",
        TelType: "",
        ThemeDemande: typeProd,
        RappelQuand: "",
        IDContenus: idContenus,
        Date: ""
    });



    const handleForm = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
        if (e.target.name === "RappelQuand" && e.target.value === "4") {
            setOpen(true);
        } else if (e.target.name === "RappelQuand" && e.target.value !== "4") {
            setOpen(false);
        }
    };

    const handleSend = () => {
        if (!form?.Mail) {
            setTxtError("Veuillez renseigner votre adresse mail");
            setIsErrorVisible(true);
            // message.error("Veuillez renseigner votre adresse mail");
            return;
        }
        if (!form?.Question) {
            setTxtError("Veuillez renseigner votre demande");
            setIsErrorVisible(true);
            // message.error("Veuillez renseigner votre demande");
            return;
        }
        setLoading(true);
        axios.post(`/users/formulairesimplevalidation`, { form }).then(res => {
            console.log(res);

            setForm({
                Origine: "",
                URL: location.pathname,
                Nom: "",
                Prenom: "",
                Mail: "",
                Tel: "",
                Question: "",
                Civ: "",
                Adresse: "",
                CP: "",
                Ville: "",
                Pays: "",
                TelType: "",
                ThemeDemande: typeProd,
                RappelQuand: "",
                IDContenus: idContenus,
                Date: ""
            });
            window.gtag("event", 'conversion', { send_to: 'AW-1070438963/Q79pCNvO7oMBELO0tv4D' });
            setLoading(false);
            setTxtError("Votre demande a bien été envoyée");
            setIsErrorVisible(true);
            // message.success("Votre demande a bien été envoyée");
            // onClick();
        }).catch(err => {
            setTxtError("Une erreur est survenue, veuillez réessayer plus tard");
            setIsErrorVisible(true);
            // message.error("Une erreur est survenue, veuillez réessayer plus tard");
            setLoading(false)
        });
    };

    const handleSendWCB = () => {


        if (!form?.Mail) {
            // message.error("Veuillez renseigner votre adresse mail");
            setTxtError("Veuillez renseigner votre adresse mail");
            setIsErrorVisible(true);
            return;
        }

        if (form?.RappelQuand === "4" && !form?.Date) {
            // message.error("Veuillez choisir une date de rappel");
            setTxtError("Veuillez choisir une date de rappel");
            setIsErrorVisible(true);
            return;
        }
        if (!form.Tel) {
            // message.error("Veuillez renseigner votre numéro de téléphone pour être rappelé");
            setTxtError("Veuillez renseigner votre numéro de téléphone pour être rappelé");
            setIsErrorVisible(true);
            return;
        }
        if (!form?.Mail) {
            // message.error("Veuillez renseigner votre adresse mail");
            setTxtError("Veuillez renseigner votre adresse mail");
            setIsErrorVisible(true);
            return;
        }
        if (!form?.Question) {
            // message.error("Veuillez renseigner votre demande");
            setTxtError("Veuillez renseigner votre demande");
            setIsErrorVisible(true);
            return;
        }

        setLoading(true);
        axios.post(`/users/demanderappelvalidation`, { form })
        .then(res => {
            setForm({
                Origine: "",
                URL: location.pathname,
                Nom: "",
                Prenom: "",
                Mail: "",
                Tel: "",
                Question: "",
                Civ: "",
                Adresse: "",
                CP: "",
                Ville: "",
                Pays: "",
                TelType: "",
                ThemeDemande: typeProd,
                RappelQuand: "",
                IDContenus: idContenus,
                Date: ""
            });
            // onClick();
            window.gtag("event", 'conversion', { send_to: 'AW-1070438963/CZAFCOb-9oMBELO0tv4D' });
            // message.success("Votre demande a bien été envoyée, nous vous contacterons dans les plus brefs délais.");
            setLoading(false);
            setTxtError("Votre demande a bien été envoyée");
            setIsErrorVisible(true);
            }).catch(err => {
            console.log(err);
            setTxtError(err?.response?.data?.message || "Une erreur est survenue, veuillez réessayer plus tard");
            setIsErrorVisible(true);
            // message.error("Une erreur est survenue, veuillez réessayer plus tard");
            setLoading(false);
        });
    };


    useEffect(() => {
        function handleClickOutside(event) {
            if (formRef.current && !formRef.current.contains(event.target)) {
                onClick();
            }
        }

        document.addEventListener('mouseup', handleClickOutside);

        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, [onClick]);
    console.log("form", form);
    return (

        <>{isErrorVisible && <PopErrorHP message={txtError} titre={"CONTACT"} onClick={() => { setIsErrorVisible(false); }} />}
            <RowStyledForm>
                <ButFermer onClick={onClick}><IoCloseCircleOutline /></ButFermer>
                {Loading && <SpinnerInline2HP />}
                <div>
                
                    <FormConnect className="maxWidth">
                        {
                            className && className.includes("rappel") ?
                                <SelectFilters id=""
                                    name="RappelQuand"
                                    type="select"
                                    value={form?.RappelQuand}
                                    onChange={handleForm}
                                >
                                    <option value=''>Me rappeler ... (choisir)</option>
                                    <option value='1'>Me rappeler dès que possible</option>
                                    <option value='2'>Me rappeler dans la journée</option>
                                    <option value='3'>Me rappeler dans la semaine</option>
                                    <option value='4'>Me rappeler à une date déterminée</option>
                                </SelectFilters>
                                : null
                        }

                        {open &&
                            <InputStyled type="date" onChange={handleForm} name="Date" style={{ width: '30%' }} min={getTodayDate()} />}

                        <SelectFilters id="Civ"
                            name="Civ"
                            type="select"
                            value={form?.Civ}
                            onChange={handleForm}
                        >
                            <option value={1}>Monsieur</option>
                            <option value={2}>Madame</option>
                        </SelectFilters>
                        <InputStyled id=""
                            name="Nom"
                            placeholder="Nom"
                            type="text"
                            value={form?.Nom}
                            onChange={handleForm}
                        />
                        <InputStyled id=""
                            name="Prenom"
                            placeholder="Prénom"
                            type="text"
                            value={form?.Prenom}
                            onChange={handleForm}
                        />
                        <InputStyled id=""
                            name="Mail"
                            placeholder="Email (Obligatoire)"
                            type="Email"
                            value={form?.Mail}
                            onChange={handleForm}
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                            title="Veuillez entrer une adresse email valide."
                        />
                        <InputStyled id=""
                            name="Tel"
                            placeholder={placeholder? placeholder: "Téléphone (Obligatoire)"}
                            // pattern="^(?:0|\(?\+33\)?\s?|0033\s?)[1-79](?:[\.\-\s]?\d\d){4}$"
                            type="text"
                            value={form?.Tel}
                            onChange={handleForm}
                        />
                        {/* <InputStyled id=""
                        name="CP"
                        placeholder="Code postal"
                        type="text"
                        value={user?.Contact?.CP}
                        /> */}
                        <div><TextareaStyled name="Question" placeholder="Demande particulière" style={{ width: '100%', height: 150}} value={form?.Question} onChange={handleForm} maxLength={maxChars}/>
                        <Chars>{form.Question.length}/{maxChars} caractères maximum</Chars></div>
                    </FormConnect>

                    <ButsForm>
                        <BoutonJaune className={`check quest ${className}`} onClick={className && className?.includes('rappel') ? handleSendWCB : handleSend} libellé="Envoyer"></BoutonJaune>
                        <BoutonJauneInverseHP className={"annuler"} libellé="Annuler" onClick={onClick}></BoutonJauneInverseHP>
                    </ButsForm>
                    <Legal><b>Vos informations personnelles sont considérées comme confidentielles. Vos coordonnées ne seront ni échangées ni revendues à des tiers, et ne seront utilisées par notre cabinet que dans le cadre de notre activité de conseil en gestion de patrimoine pour traiter votre demande.</b> Pour en savoir plus, notamment sur les personnes destinataires de ces informations, vous pouvez consulter la rubrique "informations légales" sur notre site. Vous disposez d'un droit d'accès, de rectification et d'opposition au traitement des données vous concernant (art 27 de la loi 78-17 du 6 janvier 1978 dite "Loi Informatique et Libertés" relative aux fichiers et aux libertés). Pour l'exercer adressez-vous à Haussmann Patrimoine, 4 Avenue de l’Opéra - 75001 Paris - France.
                    </Legal>
                </div>
            </RowStyledForm>

        </>
    )
}

export default FormDemandeDeContactHP;
