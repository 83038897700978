import React, {useState, useEffect} from 'react';
import {FaArrowCircleUp} from 'react-icons/fa';
import styled, {css}  from 'styled-components';
import {ScrollButton} from '../DesignSystem/ScrollButton';
import { FaArrowDownLong,FaArrowUpLong } from "react-icons/fa6";
import { GoArrowUp } from "react-icons/go";
import { animateScroll as scroll } from 'react-scroll';

const ScrollToTop = (props) => {
    const [stick, setStick] = useState(false)     
    // const onClickHandler = () => {
    //   window.scrollTo({ top: 0, behavior: 'smooth' });
    // }
    const onClickHandler = () => {
      scroll.scrollToTop({
        duration: 500,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }
    
    useEffect(() => {
      var position = window.scrollY
      window.addEventListener('scroll', function(){
        let scrollPos = window.pageYOffset;
        if(scrollPos < 200){
          setStick(false);
        }else if(scrollPos < position){
          setStick(true);
        }else{
          setStick(false);
        }
        position = scrollPos  
      });
      
      return () => {
          window.removeEventListener('scroll', function(){
            let scrollPos = window.pageYOffset;
            if(scrollPos < 200){
              setStick(false);
            }else if(scrollPos < position){
              setStick(true);
            }else{
              setStick(true);
            }
            position = scrollPos  
          });
      }
    });
  
    return (
        <ScrollButton id='bottom' type="button" onClick={onClickHandler} isStick={stick} {...props}>
          <GoArrowUp className="icon arrow-up"/>
          <GoArrowUp className="icon arrow-bottom"/>
        </ScrollButton>
    )
}

export default ScrollToTop
