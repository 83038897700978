import React from "react";
import { useState, CSSProperties } from "react";
import ReactDOM from 'react-dom';
import BarLoader from "react-spinners/BarLoader";
import styled, { useTheme } from "styled-components";
import { ColorRing, Oval,InfinitySpin,RotatingLines } from 'react-loader-spinner'
import {PopUpContainer, PopUp,PopUpTitle, Wrap} from "../DesignSystem/PopUp";

const DivLoading = styled.div`
position: absolute;
transform: translate(-50%, -50%);
top: 50%;
left: 50%;
/* background: #dcfd0033; */
border-radius: 40px;
z-index: 2;
&>svg > path {
  stroke-width: 10px;
}
`;


export default function SpinnerInline2HP() {
    const theme = useTheme();
    const color1 = theme.jaune;
    const color2 = theme.rose;
    const color3 = theme.bleuHP;
    const color4 = theme.onLineCollab;
    const color5 = theme.beigeFonce;
    const color6 = theme.grisHP;
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState(color2);

  return (
  <DivLoading className="sweet-loading">
    <InfinitySpin
    visible={true}
    width="160"
    color={color5}
    ariaLabel="infinity-spin-loading"
    
    />
    </DivLoading>
  );
}