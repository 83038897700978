import styled, {css} from 'styled-components';

export const ScrollButton = styled.button `
    position: fixed;
    right: 10px;
    bottom: -60px;
    z-index: 999;
    height: 50px;
    width: 50px;
    box-shadow: 0px 2px 2px rgba(0,0,0,0.25);
    display: block;
    padding: 0;
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    font-size: 25px;
    line-height: 60px;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    /* background-color: ${(props) => props.theme.rose}; */
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    overflow: hidden;
    ${props => props.isStick && css `
        bottom: 60px;
        opacity: 1;
        visibility: visible;
    `}
    .icon{
        position: absolute;
        top: 50%;
        left: 50%;
        color: ${(props) => props.theme.bleuHP};
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        font-size: 36px;
        &.arrow{
            &-up{
                transform: translate(-50%, -50%);
            }
            &-bottom{
                transform: translate(-50%, 80px);
            }
        }
    }
    &:hover{
        .icon{
            &.arrow{
                &-up{
                    transform: translate(-50%, -80px);
                }
                &-bottom{
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    
`;
