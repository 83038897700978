import React, { useState } from "react";
import axios from "axios";
import BoutonJaune from "../DesignSystem/BoutonJaune";
import { FormConnect, InputStyled } from "../DesignSystem/Commons/Elements";
import SpinnerHP from '../Spinners/Spinner1';
import PopErrorHP from "../Popups/PopError";
import { navigate } from "@reach/router";

export function FormSearch({ className, placeholder, mode, ...props }) {
  const [dataSearch, setDataSearch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [txt, setTxt] = useState('');
  const fetchData = () => {
    props.onCloseRecherche(true);
    setIsLoading(true);
    axios.get(`/ysw/recherche?recherche=${inputValue}`)
      .then((response) => {
        setDataSearch(response.data);
        setIsLoading(false);
        navigate("/recherche", { state: { data: response.data, inputValue: inputValue, mode: mode } });
      })
      .catch((error) => {
        setIsLoading(false);
        setDataSearch([]);
        setTxt(error?.response?.data?.error?.Erreur ? error?.response?.data?.error?.Erreur : "Une erreur s'est produite. Veuillez réessayer ultérieurement.");
        setIsErrorVisible(true);
      });
  };


  const [inputValue, setInputValue] = useState('');

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };
  return (
    <>
      {isErrorVisible && <PopErrorHP message={txt} titre={"Recherche"} onClick={() => setIsErrorVisible(false)} />}
      {isLoading && <SpinnerHP />}
      <FormConnect className={className} onSubmit={(e) => { e.preventDefault(); fetchData(); }}>
        <InputStyled
          className="blue"
          id="exampleSearch"
          name="search"
          placeholder={placeholder && placeholder !== "" ? placeholder : "Recherche"}
          type="search"
          value={inputValue} onChange={handleChange}
          onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); fetchData(); } }}
        />
        <BoutonJaune width={"110px"} className={"search maxWidthMobile withBorder"} onClick={fetchData}></BoutonJaune>

      </FormConnect>

    </>
  )
}

export default FormSearch;
